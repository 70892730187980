$primary-color: #264EC7;
$primary-hover-color: white;
$secondary-color: white;
$secondary-hover-color: #264EC7;
$box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

@mixin button($background-color, $color, $hover-background-color, $hover-color) {
  background-color: $background-color;
  color: $color;
  &:hover {
    background-color: $hover-background-color;
    color: $hover-color;
  }
}

.section-avaliacoes-limit {
  @media (max-width: 768px) {
    width: 85%;
  }
}

.circle-button-shadow {
  box-shadow: $box-shadow;
}

.blue-button {
  @include button($primary-color, $primary-hover-color, transparent, $primary-color);
}

.white-button {
  @include button(transparent, $primary-color, $primary-color, $primary-hover-color);
}
