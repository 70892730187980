.represents-images {
  width: 36px;
  height: 42px;
  object-fit: cover;
  object-position: center
}

.section-limit {
  width: 33%;
}

@media (max-width: 768px) {
  .image-width {
    width: 30%;
    display: flex;
    justify-content: center;
  }

  .text-width {
    width: 80%;
  }
  .section-limit {
    width: 100%;
  }

  .represents-images {
    height: 70px;
  }
}
