.embla {
  $slide-spacing: 2rem;
  $slide-size: 370px;
  $slide-height: 19rem;

  --slide-spacing: #{$slide-spacing};
  --slide-size: #{$slide-size};
  --slide-height: #{$slide-height};

  //padding: 1.6rem;
  margin-top: 1.6rem;
  padding-bottom: 1.6rem;
  position: relative;
  overflow: hidden;

  &__viewport {
    overflow: hidden;
  }

  &__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    @media (min-width: 768px) {
      margin-left: calc(var(--slide-spacing) * 1);
      margin-right: calc(var(--slide-spacing) * 3)
    }
  }

  &__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
    position: relative;
    @media (max-width: 768px) {
      flex: 0 0 100%;
    }
  }

  &::before {
    z-index: 99;
    content: "";
    top: 0;
    left: -10px;
    position: absolute;
    width: 30px;
    height: 100%;
    background: linear-gradient(90deg, rgb(255 255 255) 0%, rgb(255, 255, 255) 44%, rgba(255, 255, 255, 0) 100%);
  }

  &::after {
    z-index: 99;
    content: "";
    top: 0;
    right: -10px;
    position: absolute;
    width: 100px;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 3%, rgba(250, 250, 250, 0) 29%, rgba(255, 255, 255, 1) 84%);
    @media (max-width: 600px) {
      width: 50px;
    }
  }
}