.card {
  > p {
    &:nth-of-type(2n) {
      max-height: 70px;
      height: 70px;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}